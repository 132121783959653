import { React, useState, useEffect } from 'react'
import { Form, Input, Button, Select, notification, Popconfirm } from 'antd';
import axios from "../axios";
import moment from 'moment';
// import { Redirect } from "react-router-dom";
// import RegisterEdit from './RegisterEdit'; 
import { LogoutOutlined, DeleteTwoTone, EditTwoTone } from '@ant-design/icons';


const RegisterUser = ({ setIsLoggedIn }) => {

    const datetime = moment().format('YYYYMMDD h:mm:ss a')
    const [form] = Form.useForm();
    const [dataDuplicate, setDataDuplicate] = useState('')

    // const [Name, setName] = useState("");
    const [Username, setUserName] = useState("");
    const [Password, setPassword] = useState("");
    const [Password2, setPassword2] = useState("");
    const [IsActive, setIsActive] = useState();
    const [Role, setRole] = useState();
    // const upperName = Name.toUpperCase()
    const { Option } = Select;

    const [userDetail, setUserDetail] = useState([])
    const userData = (userDetail.length === 0 || userDetail === undefined) ? '' : userDetail.data

    // const [chkAdd, setChkAdd] = useState(false)

    // const tokenuser = localStorage.getItem('user');
    // const roleUser = localStorage.getItem('role');
    // const [isModalVisible, setIsModalVisible] = useState(false);
    // const [valueID, setValueID] = useState('');



    // console.log('Role',Role);
    // console.log('Username',Username);
    // console.log('Password',Password);
    // console.log('Password2',Password2);
    // console.log('IsActive',IsActive);

    const logout = () => {
        window.location.href = "/";
        localStorage.removeItem("login");
        localStorage.removeItem("user");
        localStorage.removeItem("collap");
        localStorage.removeItem("lang");
        localStorage.removeItem("role");
        localStorage.removeItem("status");
        localStorage.removeItem("exp");
        // const root = window.location.origin;
        // let url = process.env.PUBLIC_URL === "/insurance-admin" ? `${root}/insurance-admin/` : root;
        // console.log('url',url);
    };


    const checkvalue = (e) => {


        axios.post(`api/getLogin`, {
            Username: e.target.value,
        }).then((response) => {

            const data = response.data.data

            if (data.length === 1 ) {
               openNotificationWithIconWarning('warning')
            }else {
                setDataDuplicate(e.target.value) 
            }

        })
    }

    const openNotificationWithIcon = type => {
        notification[type]({
            message: 'สำเร็จ!',
            description:
                'เพิ่มผู้ใช้งานใหม่ เรียบร้อย',
        });
    };

    const openNotificationWithIconWarning = type => {
        notification[type]({
            message: 'แจ้งเตือน!',
            description:
                'email นี้มีอยู่ในระบบ',
        });
        // setDataDuplicate(false)
        setDataDuplicate('')
    };

    const handleChange = event => {
        setIsActive(event);
    };
    const handleChangeRole = event => {
        setRole(event);
    };


    const handlePass = (values) => {

        async function sha256(message) {
            // encode as UTF-8
            const msgBuffer = new TextEncoder('utf-8').encode(message);

            // hash the message
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

            // convert ArrayBuffer to Array
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            // convert bytes to hex string
            const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');

            setPassword2(hashHex);
        }
        sha256(values.target.value)
        setPassword(values.target.value);

    };

    const onFinish = (values) => {

        form.resetFields();
        // setName("");
        setRole("");
        setUserName("");
        setPassword("");
        setPassword2("");
        openNotificationWithIcon('success')
    };

    const onFinishFailed = (errorInfo) => {
        console.log('onFinishFailed Failed:', errorInfo);
    }

    // const getUserDetail = () => {
        // axios.get(`api/getdatauser/`).then((response) => {
        //     setUserDetail(response.data)
        //     setChkAdd(true)
        // })
    // }



    useEffect(() => {

        axios.get(`api/getdatauser`).then((response) => {


            setUserDetail(response.data)
            // setChkAdd(true)
        })

    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    // userDetail

    const addUserDetail = () => {

        axios.post(`api/adddatauser`, {

            // Name: upperName,
            Name: Role,
            Username: Username,
            Password: Password,
            Password2: Password2,
            IsActive: IsActive,
            CreatedDate: datetime,
            UpdatedDate: datetime


        }).then(() => {

            setUserDetail([
                ...userDetail,
                {
                    // Name: upperName,
                    Name: Role,
                    Username: Username,
                    Password: Password,
                    Password2: Password2,
                    IsActive: IsActive,
                    CreatedDate: datetime,
                    UpdatedDate: datetime
                }
            ])

            // setChkAdd(false)
           
        })

        window.location.href = "/account";
    }



    const [newStatusRole, setNewStatusRole] = useState(0)
    const [selectStatusRole, setSelectStatusRole] = useState('')
    const [selectIdRole, setSelectIdRole] = useState('')
    const [selectIdRoleId, setSelectIdRoleId] = useState()
    const [selectCheckRole, setSelectCheckRole] = useState(false)

    

    const handleChangeUpdateRole = (event) => {

        const numEvent = Number(event)
        setNewStatusRole(numEvent);
        setSelectStatusRole(event);
        setSelectCheckRole(true);

    };

    const handleChangeUpdate2Role = (Name, id) => {

        setSelectIdRole(Name);
        setSelectIdRoleId(id);
    }

    const [newStatus, setNewStatus] = useState(0)
    const [selectStatus, setSelectStatus] = useState('')
    const [selectId, setSelectId] = useState()
    const [selectCheck, setSelectCheck] = useState(false)

    const handleChangeUpdate = (event) => {

        const numEvent = Number(event)
        setNewStatus(numEvent);
        setSelectStatus(event)
        setSelectCheck(true)
    };

    const handleChangeUpdate2 = (id) => {

        setSelectId(id)
    }

    const updateUserDetail = (id, isActive, roleName) => {

        let chk = (selectCheck === false) ? isActive : newStatus;
        let chkRole = (selectCheckRole === false) ? roleName : selectStatusRole;

        setSelectCheck(false);
        setSelectCheckRole(false);

        axios.put(`api/updatedatauser`, { statustype: chk, id: id, updatedate: datetime, statusname: chkRole }).then((response) => {

            setUserDetail(
                userDetail.map((val) => {

                    return val.id === id ? {
                        id: val.id,
                        // Name: val.Name,
                        Name: chkRole,
                        Username: val.Username,
                        Password: val.Password,
                        Password2: val.Password2,
                        IsActive: chk,
                        CreatedDate: val.CreatedDate,
                        UpdatedDate: datetime

                    } : val;
                })

            )
            
        })
        
        window.location.href = "/account";

    }
    const deleteUserDetail = (id) => {
        

        axios.delete(`api/deldatauser/${id}`).then((response) => {
            // setUserDetail(
            //     userDetail.filter((val) => {
            //         return val.id != id;
            //     })

            // )

        })

        window.location.href = "/account";

    }


    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };

    const flushRedis = () => {
        (async () => {
        axios.get(`/api/flush`).then((response) => {

            openNotificationWithIconFlush('success')

            }).catch(function (error) {

            console.log('error.response', error.response);

            });
        })();
    };
    
    const openNotificationWithIconFlush = type => {
        notification[type]({
            message: 'success!',
            description:
                'Flush Completed',
        });
    };

    return (
        <div className="registerbg">
            {/* {(roleUser !== 'SUPERADMIN') ? <Redirect push to={`/`} /> : ""} */}

            <div className="registerlayout">
                <div className="formregister">
                    {/* <a type="button" href="/#" onClick={logout}>&nbsp; <LogoutOutlined style={{ color: "red", fontSize: "15px", fontWeight: "600" }} /></a> */}
                    <div className="nav-backend">
                        <div className="heade-title-box">Account access management</div>
                        <Button onClick={() => openInNewTab("/")} type="primary">View Dashboard</Button>
                        <Button className="btn-flushredis" onClick={flushRedis}>Flush Redis</Button>
                        <Button onClick={logout} type="primary" danger shape="circle" style={{display:"flex",alignItems:"center",justifyContent:"center"}}><LogoutOutlined style={{fontSize:"15px"}}/></Button>
                    </div>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <Form.Item
                                    // label="Username (email)"
                                    name="username"
                                    rules={[{ required: true, message: 'Username' }]}
                                    onBlur={checkvalue}
                                >
                                    <Input placeholder="Username (email)" type="email" onChange={(event) => {
                                        setUserName(event.target.value);
                                    }} />
                                </Form.Item>
                            </div>
                            <div className="col-6 col-md-3">
                                <Form.Item
                                    // label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Password' }]}
                                >
                                    <Input.Password placeholder="Password" onChange={handlePass} />
                                </Form.Item>
                            </div>
                            <div className="col-6 col-md-2">
                                <Form.Item
                                    // label="Role"
                                    name="role"
                                    rules={[{ required: true, message: 'Role' }]}
                                >
                                    <Select placeholder="Role" allowClear value={Role} onChange={handleChangeRole}>
                                        <Option value="B52Dashboard">B52 Dashboard</Option>
                                        <Option value="LeadPlatform">Lead Platform</Option>
                                        <Option value="SearchOnly">Search Only</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6 col-md-2">
                                <Form.Item
                                    // label="Status"
                                    name="IsActive"
                                    rules={[{ required: true, message: 'Status' }]}
                                >
                                    <Select placeholder="Status" allowClear value={IsActive} onChange={handleChange}>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Non Active</Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-6 col-md-2">
                                <Form.Item style={{ justifyContent: "center" }}>
                                    {/* <Button type="primary" htmlType="submit" disabled={(dataDuplicate === '' || dataDuplicate === true) ? false : true} onClick={addUserDetail}> */}
                                    <Button type="primary" htmlType="submit" disabled={((dataDuplicate === '' || Role === undefined || Username === '' || Password === '' || Password2 === '' || IsActive === undefined)) ? true : false} onClick={addUserDetail}>
                                        {/* <Button type="primary" htmlType="submit"  onClick={addUserDetail}> */}
                                        Add
                                    </Button>
                                    {" "}
                                    {/* <Button  type="success" onClick={getUserDetail}>
                                                แสดงข้อมูล
                                            </Button> */}
                                </Form.Item>
                            </div>
                        </div>
                    </Form>

                </div>
            </div>


            <div className="registeruser">


                <div style={{ display: 'grid', gridTemplateColumns: '29% 20% 20% 19% 6% 6%', textAlign: 'center', color:'white', background: '#6c757d', padding: '10px 0' }}>
                    <div>username</div>
                    <div>role</div>
                    <div>status</div>
                    <div>create date</div>
                    <div>update</div>
                    <div>del</div>
                </div>


                {(userDetail.length === 0 || userDetail === undefined) ? '' : userData.map((val, index) => {


                    return (
                        <div key={index} style={{ padding: '10px 0', borderBottom: '1px solid #E7EAE6' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '29% 20% 20% 19% 6% 6%', textAlign: 'center' }}>

                                <div>{val.Username}</div>

                                <div>
                                    {/* {val.Name} */}

                                    {
                                        (val.Name === "SUPERADMIN") ? val.Name :

                                            <Select style={{ width: "100%", maxWidth:"150px" }} className={(val.Name === "B52Dashboard") ? "select-B52Dashboard" : (val.Name === "SearchOnly") ? "select-SearchOnly" : "select-LeadPlatform"} value={val.id === selectIdRoleId ? selectStatusRole : (val.Name === "B52Dashboard") ? "B52 Dashboard" : (val.Name === "SearchOnly") ? "Search Only" : "Lead Platform"} onChange={handleChangeUpdateRole} onSelect={() => handleChangeUpdate2Role(val.Name, val.id)} disabled={(val.IsActive === false) ? true : false}>
                                                <Option value="B52Dashboard">B52 Dashboard</Option>
                                                <Option value="LeadPlatform">Lead Platform</Option>
                                                <Option value="SearchOnly">Search Only</Option>
                                            </Select>
                                    }

                                </div>
                                <div>
                                    <Select style={{ width: "100%", maxWidth:"150px" }} className={(val.IsActive === true) ? "select-true" : "select-false"} value={val.id === selectId ? selectStatus : (val.IsActive === true || val.IsActive === 1) ? "Active" : "No Active"} onChange={handleChangeUpdate} onSelect={() => handleChangeUpdate2(val.id)}>
                                        <Option value="1">Active</Option>
                                        <Option value="0">No Active</Option>
                                    </Select>
                                </div>
                                <div>{moment(val.CreatedDate).format('YYYYMMDD')}</div>
                                <div>

                                    {/* <Button type="primary" onClick={() => updateUserDetail(val.id,val.IsActive,val.Name)}>Update</Button> */}

                                    {val.id === selectId || val.id === selectIdRoleId ? <EditTwoTone onClick={() => updateUserDetail(val.id, val.IsActive, val.Name)} /> : ""}

                                    {/* <EditTwoTone onClick={() => updateUserDetail(val.id,val.IsActive,val.Name)} /> */}
                                    {/* <Button type="primary" danger onClick={() => deleteUserDetail(val.id)}>Delete</Button> */}
                                </div>
                                <div>

                                    {val.IsActive === false ?

                                        <Popconfirm
                                            title="Are you sure to delete this user?"
                                            onConfirm={() => deleteUserDetail(val.id)}
                                            onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            {/* <a href="#">Delete</a> */}
                                            {/* <Button type="primary" danger >Delete</Button> */}
                                            <DeleteTwoTone twoToneColor="#ff0000" />
                                        </Popconfirm>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>

        </div>
    )
}
export default RegisterUser