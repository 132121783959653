import { React, useState, useEffect } from 'react'
import { Form, Input, Button, Alert } from 'antd';
import axios from "../axios";


const LoginPage = () => {


    const [alerts, setIAlert] = useState(false)
    const [setdataAdmin, setAdmin] = useState(false)
    const [setdataOther, setOther] = useState(true)
    const [hexCode, setHexCode] = useState([0, 0])
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = (values) => {

        async function sha256(message) {
            // encode as UTF-8
            const msgBuffer = new TextEncoder('utf-8').encode(message);

            // hash the message
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

            // convert ArrayBuffer to Array
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            // convert bytes to hex string
            const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
            // console.log(hashHex);
            // return setHexCode(hashHex);


            return setHexCode([values.username.trim(), hashHex])

        }
        sha256(values.password);


    };

    const onFinishFailed = (errorInfo) => {
        console.log('onFinishFailed Failed:', errorInfo);
    }



    const logout = () => {
        // window.location.href = "/";
        localStorage.removeItem("login");
        localStorage.removeItem("user");
        localStorage.removeItem("collap");
        localStorage.removeItem("lang");
        localStorage.removeItem("role");
        localStorage.removeItem("status");
        localStorage.removeItem("exp");
        // const root = window.location.origin;
        // let url = process.env.PUBLIC_URL === "/insurance-admin" ? `${root}/insurance-admin/` : root;
        // console.log('url',url);
    };




    useEffect(() => {

        const getdata = () => {
            (async () => {
                setIsLoading(false)
                try {
                    if (hexCode[0] !== 0 || hexCode[1] !== 0) {
                        axios.post(`api/getLogin/`, {
                            Username: hexCode[0],
                            Password: hexCode[1]
                        }).then((response) => {

                            const data = response.data.data
                            const dataActive = (data.length === 0) ? "" : data[0].IsActive


                            // data[0].id
                            // data[0].Name
                            // data[0].IsActive
                            // data[0].UpdatedDate
                            // data[0].Username
                            // data[0].CreatedDate


                            if (data.length === 0 || dataActive === false) {

                                setIAlert(true)
                                setIsLoading(false)
                                logout();
                                
                            } else {
                                
                                var datePlus = new Date(Date.now() + 2 * (60 * 60 * 1000)); // exp 2 hr.
                                localStorage.setItem("exp", datePlus);
                                localStorage.setItem('login', true);
                                localStorage.setItem('role', data[0].Name);
                                localStorage.setItem('status', (dataActive === true) ? true : false);
                                setIAlert(false)
                                setIsLoading(true)

                                if (data[0].Name === "SUPERADMIN") {


                                    window.location.href = "/account";
                                    // window.location.href = "/registeruser";

                                }
                                // else if (response.data.data.Name === "LeadPlatform"){

                                //     localStorage.removeItem("login");
                                //     localStorage.removeItem("user");
                                //     localStorage.removeItem("role");
                                //     localStorage.removeItem("status");
                                //     localStorage.removeItem("exp");

                                //     setTimeout(() => {
                                //       window.location.href = "https://lead.tjdplatform.com/";
                                //     }, 10);

                                // }
                                else if (data[0].Name === "B52Dashboard") {


                                    window.location.href = "/";

                                }
                                else if (data[0].Name === "SearchOnly") {


                                    window.location.href = "/profile";

                                }
                                else if (data[0].Name === "LeadPlatform"){

                                    window.location.href = "/";

                                }
                            }
                        })
                    }

                } catch (error) {

                    console.log("Api error : ", error.response);

                }

            })();
        }
        getdata();

    }, [hexCode])

    return (
        <div className="container d-flex justify-content-center ">
            <div className="card" style={{ width: "40%", textAlign: "center", marginTop: "5em", padding: "0.5em" }}>
            {

            isLoading === true ?
                <div className='save-load'>
                    Loading...
                </div> : ''

            }
                {/* {(codeShop !== '') ? <Redirect push to="{`/profile?id=${shopcode.trim()}`}" /> : ""} */}

                {(alerts === false) ? "" : <Alert message="ชื่อผู้ใช้งานหรือรหัสผ่าน ไม่ถูกต้อง" type="error" showIcon />}
                <div className="logo-b52">
                    <img src="./images/b52logo.jpg" alt="" width="100" />
                </div>

                <div className="card-body">
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            // label="ชื่อผู้ใช้งาน"
                            name="username"
                            rules={[{ required: true, message: 'กรุณาใส่ชื่อผู้ใช้งาน!' }]}
                            className="classform"

                        >
                            <Input placeholder="ชื่อผู้ใช้งาน" />
                        </Form.Item>
                        <Form.Item
                            // label="รหัสผ่าน"
                            name="password"
                            rules={[{ required: true, message: 'กรุณาใส่รหัสผ่าน!' }]}
                            className="classform"
                        >
                            <Input.Password placeholder="รหัสผ่าน" />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" >
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default LoginPage;