import { React, useState } from 'react';
// import { Navigate } from "react-router-dom";
// import axios from '../axios';
import BoxRegion from 'module/boxRegion';
import BoxMap from 'module/boxMap';
import BoxRadio from 'module/boxRadio';
// import { UseFetchMain } from 'services/useFetch';
// import { Form } from 'antd';
// import { ChangeNameTypeTH,ChangeNameType,ChangeProvinceId,ChangeRegionId,ChangeDistId,ChangeShopTypeId } from "services/changeData";
import {currencyFormat} from 'services/currencyFormat'
import SubMainPage from './subMainPage';


function MainPage() {


  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  // const [counter, setCounter] = useState(0);
  const initialValue = 0;
  // const [form] = Form.useForm();

  const [radioBlank, setRadioBlank] = useState('');

  const [inputShopTypeNum, setInputShopTypeNum] = useState('');
  const [checkBtnShowRadio, setCheckBtnShowRadio] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingInput, setIsLoadingInput] = useState(false)


  // const [isLoading, setIsLoading] = useState(false)
  // const [isLoadingInput, setIsLoadingInput] = useState(false)




  const [dataAllInput, setDataAllInput] = useState([]);
  const [newDataInput, setNewDataInput] = useState([]);
  
  const [dataAllInputFull, setDataAllInputFull] = useState([]);
  const [newDataAllInputFull, setNewDataAllInputFull] = useState([]);
  
  const result = newDataInput.length === 0 ? dataAllInput : newDataInput;
  const dataAllInputFulls = newDataAllInputFull.length === 0 ? dataAllInputFull : newDataAllInputFull;
  
  const [dataAll, setDataAll] = useState([]);
  const [newDataAll, setNewDataAll] = useState([]);
  const dataAllLength = (dataAll.length === 0 || dataAll === undefined) ? '' : newDataAll.length === 0 ? dataAll.data : newDataAll

  const getProvinces = dataAll.length === 0  || dataAll === undefined ? '' : getUniqueListBy(dataAllLength, 'Province')
  const itemsProvinces = dataAll.length === 0  || dataAll === undefined ? '' : getProvinces.map(items => items.Province)
  const itemsCountfollow = dataAll.length === 0  || dataAll === undefined ? '' : dataAllLength.map(items => items.countfollow)
  const itemsCountfollows = dataAll.length === 0  || dataAll === undefined ? '' : itemsCountfollow.filter(elements => {return elements !== null;});
  const itemsCountfollowsUser = dataAll.length === 0  || dataAll === undefined ? '' : itemsCountfollows.reduce(
    (accumulator, currentValue) => accumulator + currentValue, initialValue
  );


  return (
    <>


      <div className='fixed-top d-flex flex-column'>
        
        {/* start filter */}
        <SubMainPage 
        
        setDataAllInputs={setDataAllInput}
        setNewDataInputs={setNewDataInput}
        setDataAllInputFulls={setDataAllInputFull}
        setDataAlls={setDataAll}
        setNewDataAlls={setNewDataAll}
        setInputShopTypeNums={setInputShopTypeNum}
        setIsLoadings={setIsLoading}
        setIsLoadingInputs={setIsLoadingInput}
        setRadioBlanks={setRadioBlank}

        />
        {/* end filter */}
      </div >

      <div className='h-top'></div>
      {/* start total data */}

     


      <div className="container-fluid">
      <div className ="alert alert-secondary mt-2 mb-0" style={{backgroundColor:'#E6E6E6' }} role="alert">
        <div className="row g-2">

          
          <div className="col-6 col-md-3">
            <div className="card ">
              <div className="card-header text-white bg-success" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
{/* 
                {
                  isLoading ?
                    <div>
                      {`กำลังโหลด...`}
                    </div> : "จำนวนร้านค้าทั้งหมด"
                } */}

                จำนวนร้านค้าทั้งหมด
              </div>
              <div className="card-body text-success" >
                <div>
                <label className='num-text'>{currencyFormat(dataAllLength.length)}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-header text-white bg-success" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>จำนวนจังหวัดทั้งหมด</div>
              <div className="card-body text-success">
                <div>
                <label className='num-text'>{itemsProvinces.length}/77</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-header text-dark bg-warning" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>จำนวนร้านค้าที่มีลูกค้าติดตาม</div>
              <div className="card-body text-secondary">
                <div>
                  <label className='num-text'>{currencyFormat(itemsCountfollows.length)}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-header text-dark bg-warning" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>จำนวนลูกค้าของร้านค้า</div>
              <div className="card-body text-secondary">
                <div>
                <label className='num-text'>{itemsCountfollowsUser ? currencyFormat(itemsCountfollowsUser) : 0}</label>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
      </div>
      {/* end total data */}

      <div className="container-fluid">
        <BoxRadio dataAll={dataAllLength} dataInputFull={dataAllInputFulls} inputShopTypeNum={inputShopTypeNum}  checkBtnShowRadio={checkBtnShowRadio} radioBlank={radioBlank}/>
      </div>

      <div className="container-fluid">
        <div className="row g-2 mt-2">
          <div className="col-12">
            <BoxRegion dataAll={dataAllLength}/>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row g-2 mt-2">
          <div className="col-12">
            <BoxMap dataAll={dataAllLength}/>
          </div>
        </div>
      </div>
     
    </>
  )
}

export default MainPage